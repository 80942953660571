import React, { useEffect, useState } from 'react';
import CustomerForm from './components/CustomerForm';
import './index.css';
import { Box, CssBaseline } from '@mui/material';
import Container from '@mui/material/Container';
import axios from 'axios';
import SubmissionMessage from './components/SubmissionMessage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import formatPhone from './utils/formatPhoneForUser';

const App = () => {
	const errorColor = '#c0392b';
	const brandPromotionalMessage =
		'Please enter your name and phone number to stay in touch with us!';
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [brandColor, setBrandColor] = useState('#03B856'); // default MUI color, will render until API request is successful
	const [businessName, setBusinessName] = useState('');
	const [apiError, setApiError] = useState(false);
	const [apiErrorMessage, setApiErrorMessage] = useState('');
	const [_business, setBusiness] = useState('');
	const [loading, setLoading] = useState(false);
	const [isIncludingPhone, setIsIncludingPhone] = useState(true);
	const [isIncludingEmail, setIsIncludingEmail] = useState(false);

	//set controlled input states
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [phoneNumberError, setPhoneNumberError] = useState(false);

	const url = window.location.href;
	const splitUrl = url.split('/');
	const twilioNumber = splitUrl[splitUrl.length - 1];

	//updates the key-value pair for the input field that triggered the event
	const handleNameChange = (event) => {
		const value = event.target.value;
		setName(value);
	};

	const handlePhoneChange = (event) => {
		const value = event.target.value;
		const formatted = formatPhone(value);
		setPhoneNumber(formatted);
	};

	const handleEmailChange = (event) => {
		const value = event.target.value;
		setEmail(value);
	};

	// Populate states
	useEffect(() => {
		(async function fetchBusinessData() {
			try {
				const response = await axios.post(
					'/api/get-business-information',
					{
						twilioNumber,
					}
				);
				if (response.data && response.status === 200) {
					setBrandColor(response.data.brandColor);
					setBusinessName(response.data.businessName);
					setBusiness(response.data._business);
					document.title = response.data.businessName;

					if (response.data._business) {
						const formDataResponse = await axios.post(
							'/api/get-opt-in-form-options/',
							{
								_business: response.data._business,
							}
						);

						if (
							formDataResponse.data &&
							!formDataResponse.data.error
						) {
							const { data } = formDataResponse;

							setIsIncludingEmail(
								data.optInFormOptions.isIncludingEmail
							);
							setIsIncludingPhone(
								data.optInFormOptions.isIncludingPhone
							);
						}
					}
				}
			} catch (e) {
				setApiError(true);
				setApiErrorMessage(e.response.data.message);
				setBrandColor(errorColor);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resetInputs = () => {
		setName('');
		setPhoneNumber('');
		setEmail('');
		setLoading(false);
		setPhoneNumberError(false);
		setNameError(false);
		setEmailError(false);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (name.trim() === '') {
			setNameError(true);
			return;
		}

		setNameError(false);

		if (isIncludingPhone) {
			if (phoneNumber.trim() === '' || phoneNumber.trim().length < 12) {
				setPhoneNumberError(true);
				return;
			}
		}

		setPhoneNumberError(false);

		if (isIncludingEmail) {
			if (email.trim() === '' || !email.includes('@')) {
				setEmailError(true);
				return;
			}
		}

		setEmailError(false);
		setLoading(true);

		// Send data to backend
		(async function sendFormData() {
			try {
				const response = await axios.post('/api/submit-form', {
					name,
					phoneNumber,
					email,
					_business,
					businessName,
					isIncludingEmail,
					isIncludingPhone,
				});

				if (response) {
					resetInputs();
					setIsSubmitted(true);
					setApiError(false);
				}
			} catch (e) {
				//handle error
				resetInputs();
				setApiError(true);
				setApiErrorMessage(e.response.data.message);
				setIsSubmitted(true);
			}
		})();

		// after a time out return them back to the original page
		// this could maybe return back to the home page if the subscription text was successfully sent
		// that way users have the ability to click a "didn't receive a text?" message to make sure they
		// successfully subscribe in the event of a failed API request.
		setTimeout(() => {
			setIsSubmitted(false);
		}, '5000');
	};

	//create theme based of business color
	const theme = createTheme({
		palette: {
			primary: {
				main: brandColor,
			},
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box
				sx={{
					display: 'flex',
					borderLeft: `.625rem solid ${theme.palette.primary.main}`,
					height: '100dvh',
				}}
			>
				<Container component="main" maxWidth="sm">
					<Box
						sx={{
							marginTop: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						{/*once the form is completed and values were submitted successfully the component will re-render as a success message.*/}
						{/* <SubmissionMessage backgroundColor={theme.palette.primary.main} twilioNumber={twilioNumber}/> */}
						{isSubmitted ? (
							<SubmissionMessage
								backgroundColor={theme.palette.primary.main}
								twilioNumber={twilioNumber}
								apiError={apiError}
								apiErrorMessage={apiErrorMessage}
								errorColor={errorColor}
								setIsSubmitted={setIsSubmitted}
								resetInputs={resetInputs}
							/>
						) : (
							<CustomerForm
								brandPromotionalMessage={
									brandPromotionalMessage
								}
								handleNameChange={handleNameChange}
								handlePhoneChange={handlePhoneChange}
								handleEmailChange={handleEmailChange}
								nameError={nameError}
								phoneNumberError={phoneNumberError}
								emailError={emailError}
								name={name}
								email={email}
								phoneNumber={phoneNumber}
								businessName={businessName}
								handleSubmit={handleSubmit}
								apiError={apiError}
								apiErrorMessage={apiErrorMessage}
								errorColor={errorColor}
								loading={loading}
								isIncludingPhone={isIncludingPhone}
								isIncludingEmail={isIncludingEmail}
							/>
						)}
					</Box>
				</Container>
			</Box>
		</ThemeProvider>
	);
};

export default App;
