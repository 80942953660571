import React, { useState } from 'react';
import {
	Box,
	Typography,
	TextField,
	Link,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormSkeleton from './FormSkeleton';

function CustomerForm({
	brandPromotionalMessage,
	handleNameChange,
	handlePhoneChange,
	handleEmailChange,
	nameError,
	phoneNumberError,
	emailError,
	name,
	email,
	phoneNumber,
	businessName,
	handleSubmit,
	apiError,
	apiErrorMessage,
	errorColor,
	loading,
	isIncludingPhone,
	isIncludingEmail,
}) {
	const [isTwilioOptIn, setIsTwilioOptIn] = useState(false);

	return (
		<Box>
			{businessName === '' ? (
				<Box>
					<FormSkeleton
						apiError={apiError}
						apiErrorMessage={
							apiErrorMessage
								? apiErrorMessage
								: 'Server error! Please try again in a few minutes.'
						}
						errorColor={errorColor}
					/>
				</Box>
			) : (
				<Box>
					<Box sx={{ textAlign: 'center' }}>
						{controlTextSize(businessName)}
					</Box>
					<Box
						sx={{
							mt: 1,
							textAlign: 'center',
							margin: 0,
							padding: 0,
						}}
					>
						<Typography
							component="span"
							variant="body1"
							align="center"
						>
							{brandPromotionalMessage}
						</Typography>
					</Box>
					<Box component="form" noValidate sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							fullWidth
							id="name"
							label="Name"
							name="name"
							autoFocus
							variant="outlined"
							error={nameError}
							onChange={handleNameChange}
							helperText={nameError ? 'Name is required' : ''}
							value={name}
						/>
						{isIncludingPhone ? (
							<TextField
								margin="normal"
								fullWidth
								name="phone"
								label="Mobile phone"
								type="tel"
								id="phone"
								variant="outlined"
								error={phoneNumberError}
								helperText={
									phoneNumberError
										? 'Invalid phone number'
										: ''
								}
								onChange={handlePhoneChange}
								value={phoneNumber}
							/>
						) : null}

						{isIncludingEmail ? (
							<TextField
								margin="normal"
								fullWidth
								name="email"
								label="Email Address"
								type="email"
								id="email"
								variant="outlined"
								error={emailError}
								helperText={emailError ? 'Invalid email' : ''}
								onChange={handleEmailChange}
								value={email}
							/>
						) : null}
						<FormControlLabel
							value="top"
							onChange={() => setIsTwilioOptIn(!isTwilioOptIn)}
							control={<Checkbox color="primary" />}
							style={{
								marginRight: '0px',
							}}
							label={
								<Typography
									color="grey"
									variant="body1"
									component="span"
									style={{
										fontSize: '0.9rem',
									}}
								>
									By submitting this form, you consent to
									receive marketing text messages from{' '}
									{businessName} and comply with our&nbsp;
									<Link
										href="https://www.chekkit.io/text-policy"
										color="inherit"
										target="_blank"
										rel="noopener"
										underline="hover"
									>
										policy
									</Link>
									. Standard message and data rates may apply.
									Message frequency varies.
								</Typography>
							}
							labelPlacement="end"
						/>
						<LoadingButton
							type="submit"
							fullWidth
							sx={{ mt: 3, mb: 2, fontWeight: 600 }}
							onClick={handleSubmit}
							variant="contained"
							color="primary"
							loading={loading}
							disabled={!isTwilioOptIn}
						>
							Submit
						</LoadingButton>
					</Box>
				</Box>
			)}
		</Box>
	);
}

function controlTextSize(businessName) {
	const variant = businessName.length > 25 ? 'h3' : 'h2';
	const component = variant === 'h3' ? 'h3' : 'h1';

	return (
		<Typography
			component={component}
			variant={variant}
			sx={{
				marginBottom: 4,
				fontWeight: 700,
			}}
		>
			{businessName}
		</Typography>
	);
}

export default CustomerForm;
