import React from 'react';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import ErrorIcon from '@mui/icons-material/Error';

const FormSkeleton = (props) => {
	const isSmallToMedium = useMediaQuery((theme) =>
		theme.breakpoints.up('sm')
	);
	const isXsToSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<>
			{props.apiError ? (
				<Box
					sx={{
						textAlign: 'center',
					}}
				>
					<ErrorIcon
						sx={{
							color: props.errorColor,
							fontSize: '8rem',
							textAlign: 'center',
						}}
					/>
					<Typography align="center" variant="h3" component="h3">
						{props.apiErrorMessage}
					</Typography>
					<Typography
						sx={{
							paddingTop: 5,
						}}
						align="center"
						variant="h6"
					>
						Please try again or contact support@chekkit.io
					</Typography>
				</Box>
			) : (
				<>
					<Box
						sx={{
							textAlign: 'center',
							width: isXsToSmall
								? '230px'
								: '350px' || isSmallToMedium
								? '550px'
								: '370px',
						}}
					>
						<Typography
							component="h1"
							variant="h2"
							sx={{
								mb: 4,
								fontWeight: 700,
							}}
						>
							<Skeleton variant="text" />
						</Typography>
					</Box>
					<Box
						sx={{
							mt: 1,
							textAlign: 'center',
							margin: 0,
							padding: 0,
						}}
					>
						<Typography
							component="span"
							variant="body1"
							align="center"
						>
							<Skeleton variant="text" />
						</Typography>
					</Box>
					<Box component="form" noValidate>
						<TextField
							margin="normal"
							fullWidth
							value={''}
							placeholder="Loading..."
							disabled={true}
						/>
						<TextField
							margin="normal"
							fullWidth
							error={false}
							value={''}
							placeholder="Loading..."
							disabled={true}
						/>
						<Typography
							color="grey"
							variant="body1"
							component="span"
						>
							<Skeleton sx={{ height: 150 }} variant="text" />
						</Typography>
						<Button
							type="submit"
							fullWidth
							disabled={true}
							variant="contained"
							color="primary"
						>
							<Skeleton variant="text" />
						</Button>
					</Box>
				</>
			)}
		</>
	);
};

export default FormSkeleton;
