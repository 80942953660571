/* eslint-disable no-param-reassign */
const formatPhone = (i, country = 'NA') => {
	// NA === North America e.g default case
	if (!i) return '';

	if (typeof i === 'number') {
		i = String(i);
	}

	// check for country prefixes
	if (i.includes('+61')) {
		country = 'AUS';
	}

	if (i.includes('+1')) {
		country = 'NA';
	}

	if (i.includes('+44')) {
		country = 'UK';
	}

	// remove any non alphanumeric characters
	let input = i.replace(/\D/g, '');

	// UK numbers from db = +447XXXXXXXXX
	// return in format 07XXX XXXXXX
	if (
		(input.charAt(0) === '0' && input.charAt(1) === '7') ||
		country === 'UK'
	) {
		if (input.indexOf('44') === 0) {
			input = input.replace('44', '0');
		}

		const size = input.length;

		if (size <= 4) {
			input = `${input}`;
		} else if (size === 5) {
			input = `${input}`;
		} else {
			input = `${input.substring(0, 5)} ${input.substring(5, 11)}`;
		}

		if (size === 6 && input.charAt(5) === ' ') {
			input = input.trim();
		}

		return input;
	}

	// Australian numbers from db = +61480014468
	// Handle Australia Case
	if (input.charAt(0) === '0' || country === 'AUS') {
		if (input.indexOf('61') === 0) {
			input = input.replace('61', '0');
		}

		const size = input.length;
		if (size <= 4) {
			input = `${input}`;
		} else if (size <= 7) {
			input = `${input.substring(0, 4)} ${input.substring(4, 7)}`;
		} else {
			input = `${input.substring(0, 4)} ${input.substring(
				4,
				7
			)} ${input.substring(7, 10)}`;
		}
		return input;
	}

	// remove leading one from NA numbers
	if (input.charAt(0) === '1') {
		input = input.slice(1);
	}

	const size = input.length;
	if (size < 4) {
		input = `${input}`;
	} else if (size < 7) {
		input = `${input.substring(0, 3)} ${input.substring(3, 6)}`;
	} else {
		input = `${input.substring(0, 3)} ${input.substring(
			3,
			6
		)} ${input.substring(6, 10)}`;
	}
	return input;
};

export default formatPhone;
