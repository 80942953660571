import { useTheme } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Typography } from "@mui/material";
import formatPhone from "../utils/formatPhoneForUser";
import ErrorIcon from "@mui/icons-material/Error";

export function SubmissionMessage(props) {
  const theme = useTheme();

  return (
    <>
      {props.apiError ? (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <ErrorIcon
            sx={{
              color: props.errorColor,
              fontSize: "8rem",
              textAlign: "center",
            }}
          ></ErrorIcon>
          <Typography
            sx={{ paddingBottom: 2 }}
            align="center"
            variant="h3"
            component="h3"
          >
            {props.apiErrorMessage}
          </Typography>
          <Button
            onClick={() => {
              props.resetInputs();
              props.setIsSubmitted(false);
            }}
          >
            Go back
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            mt: 1,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CheckCircleOutlineIcon
            sx={{ color: "green", height: 100, width: 100 }}
          />
          <Box sx={{ textAlign: "center", padding: "1rem" }}>
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                marginBottom: ".5rem",
                fontWeight: 700,
              }}
              variant="h1"
              component="h2"
            >
              Thank You
            </Typography>
            <Typography variant="h6" sx={{ margin: "1rem" }}>
              You should receive a text from +1{" "}
              {formatPhone(props.twilioNumber)}
            </Typography>
            <Button
              variant="ghost"
              onClick={() => {
                props.resetInputs();
                props.setIsSubmitted(false);
              }}
              style={{ textTransform: "none" }}
            >
              Don't see a text? Click here to try again.
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

export default SubmissionMessage;
